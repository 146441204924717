<template>
  <main>
    <PageHeader></PageHeader>
    <div class="main_body" style="min-height:300px;">
      <LeftNav class="center_left"></LeftNav>
      <div class="center_right">
        <el-select class="selectContainer" v-model="provCode" placeholder="请选择省份" @change="provChange">
          <el-option v-for="item in proCodes" :key="item.entryId" :label="item.entryName" :value="item.entryId">
          </el-option>
        </el-select>
        <el-select class="selectContainer" v-model="cityCode" placeholder="请选择市区" @change="cityChange">
          <el-option v-for="item in cityCodes" :key="item.entryId" :label="item.entryName" :value="item.entryId">
          </el-option>
        </el-select>
        <el-select class="selectContainer selectContainer-last" v-model="countyCode" placeholder="请选择县城">
          <el-option v-for="(item, index) in countyCodes" :key="index" :label="item.entryName" :value="item.entryId">
          </el-option>
        </el-select>
        <mt-field label="收货地址" placeholder="请收货地址" type="text" v-model="address" :attr="{ maxlength: 160 }"></mt-field>
        <mt-field label="收货人" placeholder="请输入收货人" type="text" v-model="consigneeName" :attr="{ maxlength: 16 }">
        </mt-field>
        <mt-field label="手机号码" placeholder="请输入手机号码" type="tel" v-model="mobile" :attr="{ maxlength: 16 }"></mt-field>
        <mt-field label="固定电话" placeholder="请输入固定电话" type="tel" v-model="phone" :attr="{ maxlength: 16 }"></mt-field>
        <mt-field label="邮箱地址" placeholder="请输入邮箱地址" type="email" v-model="mail" :attr="{ maxlength: 16 }"></mt-field>
        <mt-cell :title="'是否默认地址'">
          <mt-switch on-value=1 v-model="isDefault"></mt-switch>
        </mt-cell>
        <mt-cell :title="'是否集中配送'">
          <mt-switch on-value=1 v-model="centrallyFlag"></mt-switch>
        </mt-cell>
        <div>
          <el-select v-show="centrallyFlag" class="selectContainer" v-model="schoolId" placeholder="请选学校">
            <el-option v-for="item in schoolCodes" :key="item.schoolId" :label="item.schoolName" :value="item.schoolId">
            </el-option>
          </el-select>
        </div>
        <span class="maroon">请正确填写，以便您能更快收到商品</span>
        <mt-button @click="addAdress" size="large" type="primary">{{this.isUpdate ? '更新': '添加'}}</mt-button>
      </div>
    </div>
    <PageFooter></PageFooter>
  </main>
</template>

<script>
import { Toast } from 'mint-ui';
import PageHeader from '../components/PageHeader';
import PageFooter from '../components/PageFooter';
import LeftNav from '../components/LeftNav';

export default {
  data() {
    return {
      schoolId: '',
      schoolCodes: [],
      proCodes: [],
      provCode: '',
      cityCodes: [],
      cityCode: '',
      countyCodes: [],
      countyCode: '',
      address: '',
      consigneeName: '',
      mobile: '',
      phone: '',
      mail: '',
      sle: '',
      isDefault: false,
      centrallyFlag: false,
      addressId: '',
      isUpdate: this.$route.query.addressId ? true : false, // eslint-disable-line no-unneeded-ternary,max-len
    };
  },
  watch: {
    cityCode: 'updateSchool',
    countyCode: 'updateSchool',
  },
  methods: {
    updateSchool() {
      if (this.countyCode) {
        this.$http.get(`api/school/${this.countyCode}`).then((rep) => {
          this.schoolCodes = rep.data.dataList;
        });
      } else if (this.cityCode) {
        this.$http.get(`api/school/${this.cityCode}`).then((rep) => {
          this.schoolCodes = rep.data.dataList;
        });
      }
    },
    goDetail() {
      this.$router.push({
        name: 'orderdetail',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    provChange() {
      // eslint-disable-next-line no-unneeded-ternary
      if (this.provCode) {
        this.$http.get(`api/region/${this.provCode}`).then((rep) => {
          this.cityCodes = rep.data;
          if (!this.isUpdate) {
            this.cityCode = '';
          }
        });
      }
    },
    cityChange() {
      if (this.cityCode) {
        this.$http.get(`api/region/${this.cityCode}`).then((rep) => {
          if (rep.data.length === 0) {
            this.countyCode = '';
            this.countyCodes = rep.data;
          } else {
            if (!this.isUpdate) {
              this.countyCode = '';
            }
            this.countyCodes = rep.data;
          }
        });
      }
    },
    addAdress() {
      if (this.validateForm()) {
        if (!this.$route.query.addressId) {
          const formData = JSON.stringify({
            provCode: this.provCode,
            cityCode: this.cityCode,
            countyCode: this.countyCode,
            address: this.address,
            consigneeName: this.consigneeName,
            mobile: this.mobile,
            phone: this.phone,
            mail: this.mail,
            isDefault: this.isDefault ? 1 : 0,
            centrallyFlag: this.centrallyFlag ? 1 : 0,
            schoolId: this.schoolId,
          });
          this.$http.post('api/user/consignee/save', formData).then((rep) => {
            if (rep.data.code === 0) {
              Toast('添加成功');
              this.$router.push({
                name: 'addresslist',
              });
            } else {
              this.$message.error(rep.data.msg);
            }
          });
        } else {
          const formData = JSON.stringify({
            addressId: this.$route.query.addressId,
            provCode: this.provCode,
            cityCode: this.cityCode,
            countyCode: this.countyCode,
            address: this.address,
            consigneeName: this.consigneeName,
            mobile: this.mobile,
            phone: this.phone,
            mail: this.mail,
            isDefault: this.isDefault ? 1 : 0,
            centrallyFlag: this.centrallyFlag ? 1 : 0,
          });
          this.$http.post('api/user/consignee/update', formData).then((rep) => {
            if (rep.data.code === 0) {
              Toast('更新成功');
              this.$router.push({
                name: 'addresslist',
              });
            } else {
              this.$message.error(rep.data.msg);
            }
          });
        }
      }
    },
    validateForm() {
      if (!this.provCode) {
        Toast('请输入选择省份');
        return false;
      }
      if (this.centrallyFlag && !this.schoolId) {
        Toast('请选择一所学校');
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$nextTick(() => {
        this.$http.get('api/region/0').then((rep1) => {
          this.proCodes = rep1.data;
          if (this.$route.query.addressId) {
            this.$http.get(`api/user/consignee/${this.$route.query.addressId}`).then((rep) => {
              if (rep.data.code === 500) {
                if (rep.data.msg === 'token失效，请重新登录' || rep.data.msg === 'token不能为空') {
                  this.$toast('需要您登录');
                  this.$router.push({
                    name: 'login',
                    query: {
                      historyUrl: window.location.href,
                    },
                  });
                } else {
                  this.$toast(rep.data.msg);
                }
              } else {
                this.address = rep.data.data.address;
                this.addressId = rep.data.data.addressId;
                this.addressName = rep.data.data.addressName;
                // eslint-disable-next-line no-unneeded-ternary
                this.centrallyFlag = rep.data.data.centrallyFlag === 1 ? true : false;
                this.cityCode = rep.data.data.cityCode;
                this.consigneeName = rep.data.data.consigneeName;
                this.countyCode = rep.data.data.countyCode;
                this.customerId = rep.data.data.customerId;
                this.email = rep.data.data.email;
                // eslint-disable-next-line no-unneeded-ternary
                this.isDefault = rep.data.data.isDefault === 1 ? true : false;
                this.mobile = rep.data.data.mobile;
                this.phone = rep.data.data.phone;
                this.provCode = rep.data.data.provCode;
                this.schoolId = rep.data.data.schoolId;
              }
            });
          }
        });
      });
    });
  },
  components: {
    PageHeader,
    PageFooter,
    LeftNav,
  },
};
</script>

<style>
.selectContainer {
  width: 30%;
  margin-right: 1%;
  margin-top: 15px;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .selectContainer {
    width: 90%;
    margin-left: 5%;
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .selectContainer-last {
    margin-bottom: 15px;
  }
}
</style>
