<template>
    <footer id="ac-globalfooter" class="js" lang="en-US" data-analytics-region="global footer" role="contentinfo"
     aria-labelledby="ac-gf-label">
        <div class="ac-gf-content">
            <h2 class="ac-gf-label" id="ac-gf-label">Apple Footer</h2>

            <div class="menu">
                <a href="/shoppinglist?categoryId=1">三天购</a><font>|</font>
                <a href="/assistant?selected=1">荟乐助教APP</a><font>|</font>
                <a href="/reading?goodsId=1">布克悦读</a><font>|</font>
                <a href="/english?goodId=111">布克英语</a><font>|</font>
                <a href="/experiment?goodId=120">夸克小实验</a><font>|</font>
                <a href="/art?goodId=45">荟乐美术</a><font>|</font>
                <a href="/think?goodId=136">原理思维</a><font>|</font>
                <a href="/cloth">荟乐园服</a><font>|</font>
                <a href="/cl?goodId=192">区域角材料</a><font>|</font>
                <a href="/view?goodId=3">幼儿视界</a>
            </div>

            <nav class="ac-gf-directory with-5-columns" aria-label="Apple Directory" role="navigation">
                <!--googleoff: all-->
                <div class="ac-gf-directory-column">
                    <input class="ac-gf-directory-column-section-state" type="checkbox" id="ac-gf-directory-column-section-state-products">
                    <div class="ac-gf-directory-column-section">
                        <label class="ac-gf-directory-column-section-label" for="ac-gf-directory-column-section-state-products">
                            <h3 class="ac-gf-directory-column-section-title">新闻资讯</h3>
                        </label>
                        <a href="/#ac-gf-directory-column-section-state-products" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-open">
                            <span class="ac-gf-directory-column-section-anchor-label">Open Menu</span>
                        </a>
                        <a href="/#" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-close">
                            <span class="ac-gf-directory-column-section-anchor-label">Close Menu</span>
                        </a>
                        <ul class="ac-gf-directory-column-section-list">
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/news?cid=5">企业资讯</a></li>
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/news?cid=6">行业信息</a></li>
                        </ul>
                    </div>
                </div>
                <div class="ac-gf-directory-column">
                    <input class="ac-gf-directory-column-section-state" type="checkbox" id="ac-gf-directory-column-section-state-accounts">
                    <div class="ac-gf-directory-column-section">
                        <label class="ac-gf-directory-column-section-label" for="ac-gf-directory-column-section-state-accounts">
                            <h3 class="ac-gf-directory-column-section-title">在线留言</h3>
                        </label>
                        <a href="/#ac-gf-directory-column-section-state-accounts" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-open">
                            <span class="ac-gf-directory-column-section-anchor-label">Open Menu</span>
                        </a>
                        <a href="/#" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-close">
                            <span class="ac-gf-directory-column-section-anchor-label">Close Menu</span>
                        </a>
                        <ul class="ac-gf-directory-column-section-list">
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=8">在线留言</a></li>
                        </ul>
                    </div>
                </div>
                <div class="ac-gf-directory-column">
                    <input class="ac-gf-directory-column-section-state" type="checkbox" id="ac-gf-directory-column-section-state-storeservices">
                    <div class="ac-gf-directory-column-section">
                        <label class="ac-gf-directory-column-section-label" for="ac-gf-directory-column-section-state-storeservices">
                            <h3 class="ac-gf-directory-column-section-title"><a href="/about">关于荟乐</a></h3>
                        </label>
                        <a href="/#ac-gf-directory-column-section-state-storeservices" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-open">
                            <span class="ac-gf-directory-column-section-anchor-label">Open Menu</span>
                        </a>
                        <a href="/#" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-close">
                            <span class="ac-gf-directory-column-section-anchor-label">Close Menu</span>
                        </a>
                        <ul class="ac-gf-directory-column-section-list">
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=1">荟乐简介</a></li>
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=2">荟乐理念</a></li>
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=3">发展历程</a></li>
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=4">资质与荣誉</a></li>
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=5">各地荟乐</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="ac-gf-directory-column">
                    <input class="ac-gf-directory-column-section-state" type="checkbox" id="ac-gf-directory-column-section-state-education">
                    <div class="ac-gf-directory-column-section">
                        <label class="ac-gf-directory-column-section-label" for="ac-gf-directory-column-section-state-education">
                            <h3 class="ac-gf-directory-column-section-title">合作机会</h3>
                        </label>
                        <a href="/#ac-gf-directory-column-section-state-education" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-open">
                            <span class="ac-gf-directory-column-section-anchor-label">Open Menu</span>
                        </a>
                        <a href="/#" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-close">
                            <span class="ac-gf-directory-column-section-anchor-label">Close Menu</span>
                        </a>
                        <ul class="ac-gf-directory-column-section-list">
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=6">联系我们</a></li>
                        </ul>
                    </div>
                </div>
                <div class="ac-gf-directory-column">
                    <input class="ac-gf-directory-column-section-state" type="checkbox" id="ac-gf-directory-column-section-state-business">
                    <div class="ac-gf-directory-column-section">
                        <label class="ac-gf-directory-column-section-label" for="ac-gf-directory-column-section-state-business">
                            <h3 class="ac-gf-directory-column-section-title">招聘信息</h3>
                        </label>
                        <a href="/#ac-gf-directory-column-section-state-business" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-open">
                            <span class="ac-gf-directory-column-section-anchor-label">Open Menu</span>
                        </a>
                        <a href="/#" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-close">
                            <span class="ac-gf-directory-column-section-anchor-label">Close Menu</span>
                        </a>
                        <ul class="ac-gf-directory-column-section-list">
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=7">招聘信息</a></li>
                        </ul>
                    </div>
                </div>
                <div class="ac-gf-directory-column">
                    <input class="ac-gf-directory-column-section-state" type="checkbox" id="ac-gf-directory-column-section-state-responsibility">
                    <div class="ac-gf-directory-column-section">
                        <label class="ac-gf-directory-column-section-label" for="ac-gf-directory-column-section-state-responsibility">
                            <h3 class="ac-gf-directory-column-section-title">版权声明</h3>
                        </label>
                        <a href="/#ac-gf-directory-column-section-state-responsibility" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-open">
                            <span class="ac-gf-directory-column-section-anchor-label">Open Menu</span>
                        </a>
                        <a href="/#" class="ac-gf-directory-column-section-anchor ac-gf-directory-column-section-anchor-close">
                            <span class="ac-gf-directory-column-section-anchor-label">Close Menu</span>
                        </a>
                        <ul class="ac-gf-directory-column-section-list">
                            <li class="ac-gf-directory-column-section-item"><a class="ac-gf-directory-column-section-link" href="/about?selected=9">版权声明</a></li>
                        </ul>
                    </div>
                </div>
                <!--googleon: all-->
            </nav>

            <section class="ac-gf-footer">
                <div class="ac-gf-footer-legal">
                    <div class="ac-gf-footer-legal-copyright">
                        <span>北京荟乐教育科技有限公司 版权所有 2007-<font id="year">2024</font></span>
                        <span class="ac-gf-footer-legal-status">
                            <a class="ac-gf-footer-locale-link" href="https://beian.miit.gov.cn/" target="_blank">京ICP备08009514号-1</a>
                        </span>
                        <span class="ac-gf-footer-legal-status">
                            <a class="ac-gf-footer-locale-link" href="https://img.glees.cn/file/about/ICP.jpg" target="_blank">ICP经营许可证 京B2-20202557</a>
                        </span>
                        <span class="ac-gf-footer-legal-status">
                            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802029443">
                                <img src="static/jinghui.png" style="float:left;">
                                <p style="float:left;margin: 0px 0px 0px 5px;">京公网安备11010802029443号</p>
                            </a>
                        </span>
                    </div>
                </div>
            </section>
        </div>
    </footer>
</template>
<script language = "javascript">
	window.onload = function () { // eslint-disable-line
		const date = new Date();
		const year = date.getFullYear();
		document.getElementById('year').innerText = year;
	};
</script>
