<template>
  <main>
    <div class="quan-list" style="display: block;text-align: center;float: right;width: auto;">
      <div class="quan-item quan-d-item">
        <img src="static/images/yhj-d0.png" style="width: 8.35rem;height: 2.73rem;" />
        <div class="q-type" style="width: 8.35rem;height: 2.73rem;position: absolute;left: 0.2rem;top: 0;">
          <div class="q-price">
            <em v-show='this.$props.item.couponType !== "折扣券"'>￥</em>
            <strong class="num">{{this.$props.item.currentCoupon}}</strong>
            <em v-show='this.$props.item.couponType === "折扣券"'>%</em>
            <div>{{this.$props.item.couponRemark}}</div>
          </div>
          <div class="txt">
            <div class="typ-txt">{{this.$props.item.couponType}}
            </div>
          </div>
          <div style="margin-top: -0.4rem;position: absolute;left: 3rem;">
            <div class="range-item" v-show='this.$props.item.couponType === "满减券"'>满{{this.$props.item.fitAmount}}元减{{this.$props.item.currentCoupon}}</div>
            <div class="range-item" v-show='this.$props.item.couponType === "折扣券"'>满{{this.$props.item.fitAmount}}元优惠{{this.$props.item.currentCoupon}}%</div>
            <div class="range-item" v-show='this.$props.item.couponType === "无门槛券"'>立减{{this.$props.item.currentCoupon}}</div>
            <!-- <div class="range-item" v-show='this.$props.item.nextCoupon'>当前优惠截止日期: {{this.$props.item.currentEndDate}}</div>
            <div class="range-item" v-show='this.$props.item.nextCoupon && this.$props.item.couponType === "满减券" && this.$props.item.nextCoupon'>下一阶段优惠: 满 {{this.$props.item.fitAmount}}元减{{this.$props.item.nextCoupon}}</div>
            <div class="range-item" v-show='this.$props.item.nextCoupon && this.$props.item.couponType === "折扣券" && this.$props.item.nextCoupon'>下一阶段优惠: 满{{this.$props.item.fitAmount}}元优惠{{this.$props.item.nextCoupon}}%</div>
            <div class="range-item" v-show='this.$props.item.nextCoupon && this.$props.item.couponType === "无门槛券" && this.$props.item.nextCoupon'>下一阶段优惠: 立减{{this.$props.item.nextCoupon}}元</div> -->
            <div style="font-size: 0.3rem; margin-top: 0.25rem;color: #999;">{{this.$props.item.useRange}}</div>
          </div>
          <div class="q-range">
            <div style="margin-top: 0.6rem;float: left;margin-left: 2.6rem;line-height: 0.4rem;position: absolute;text-align: left;">
              <div style="font-size: 0.3rem;">{{this.$props.item.applyPostStr}}</div>
              <div style="font-size: 0.3rem;">{{this.$props.item.applyStr}}</div>
              <!-- <div style="font-size: 0.35rem;text-align: left;">{{this.$props.item.couponNo}}</div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'CouponItem',
  props: ['item'],
  methods: {
    goShoppingList(page) {
      if (page === 'shopping') {
        this.$router.push({
          name: 'shoppinglist',
          query: {
            categoryId: '1',
          },
        });
      }
    },
  },
};
</script>
<style scoped>
* {
  font-size: 0.45rem;
}
.selectContainer {
  width: 30%;
  margin-right: 1%;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.optionBtn {
  margin-right: 20px;
}
.range-item {
  color: #000;
  font-size: 0.5rem;
}
.typ-txt {
  color: #fff;
  float:left;
  font-size: 0.5rem;
}
@media only screen and (max-width: 767px) {
  .selectContainer {
    width: 90%;
    margin-left: 5%;
    margin-top: 0.3rem;
    margin-bottom: 0;
  }
  .selectContainer-last {
    margin-bottom: 0.3rem;
  }
}
.quan-list {
  display: inline-block;
  display: block;
  width: 100%;
}
.quan-item {
  position: relative;
  border: none;
  background: #fff;
  font-family: "Microsoft YaHei";
}
.quan-list .quan-item {
  float: left;
  margin: 2px 20px 28px 2px;
}
@media only screen and (max-width: 767px) {
  .quan-list .quan-item {
    float: none;
    margin: 10px auto;
  }
}
.quan-item .q-type {
  padding: 5px 0 20px 0.3rem;
  padding-left: 20px;
}
.quan-item .q-price {
  display: inline-block;
  display: block;
  color: #fff;
}
.quan-d-item .q-price {
  color: #fff;
}
.quan-item .q-price {
  display: inline-block;
  display: block;
  color: #fff;
}
.quan-d-item .q-price {
  color: #fff;
}
em,
i,
u {
  font-style: normal;
}
.quan-item .q-price em {
  float: left;
  margin: 0.26rem 0 0;
  font-family: verdana;
  font-size:  0.6rem;;
}
.quan-item .q-price em {
  float: left;
  margin: 0.26rem 0 0;
  font-family: verdana;
  font-size:  0.6rem;;
}
a,
address,
b,
big,
blockquote,
body,
center,
cite,
code,
dd,
del,
div,
dl,
dt,
em,
fieldset,
font,
form,
h1,
h2,
h3,
h4,
h5,
h6,
html,
i,
iframe,
img,
ins,
label,
legend,
li,
ol,
p,
pre,
small,
span,
strong,
u,
ul,
var {
  margin: 0;
  padding: 0;
}
.quan-item .q-price strong {
  float: left;
  line-height: 1rem;
  margin: 0 10px 0 5px;
  font-size:  0.6rem;
  font-family: arial;
  _display: inline;
}
.quan-item .q-price strong {
  float: left;
  line-height: 1rem;
  margin: 0 10px 0 5px;
  font-size: 0.6rem;
  font-family: arial;
  _display: inline;
}
.quan-item .q-price .txt {
  float: left;
  line-height: 22px;
  margin-top: 22px;
}
.quan-item .q-price .txt {
  float: left;
  line-height: 22px;
  margin-top: 22px;
}
.quan-item .q-price:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.quan-item .q-price:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.quan-item .limit {
  width: 250px;
  height: 22px;
  line-height: 22px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.quan-item .limit {
  width: 250px;
  height: 22px;
  line-height: 22px;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ftx-06,
.ftx06 {
  color: #666;
}
.quan-item .q-range {
  line-height: 22px;
  color: #999;
}
.quan-item .q-range {
  line-height: 22px;
  color: #999;
}
.quan-item .q-opbtns {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 144px;
  padding: 48px 22px 0;
  line-height: 24px;
  background: #74d2d4;
  color: #fff;
  font-size: 14px;
}
.quan-d-item .q-opbtns {
  background: #74d2d4;
}
.quan-item .q-opbtns {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 144px;
  padding: 48px 22px 0;
  line-height: 24px;
  background: #74d2d4;
  color: #fff;
  font-size: 14px;
}
.quan-d-item .q-opbtns {
  background: #74d2d4;
}
.quan-item .q-opbtns {
  padding-left: 36px;
  padding-right: 24px;
}
a {
  text-decoration: none;
}
.quan-item .q-opbtns a {
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}
.quan-item .q-opbtns .semi-circle {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 3px;
  height: 192px;
  background: url(//misc.360buyimg.com/user/quan/3.0.0/css/i/semi-circle.png)
    repeat-y;
}
.mod-main {
  padding: 10px;
  background-color: #fff;
}
.m,
.mb,
.mc,
.mt,
.sm,
.smb,
.smc,
.smt {
  overflow: hidden;
  zoom: 1;
}
.mod-comm .mt {
  margin-bottom: 0;
  background: #fff;
  padding: 20px 20px 0;
}
.mod-main .mt {
  display: inline-block;
  display: block;
  padding: 10px;
}
.mod-main .mt h3 {
  float: left;
  font-family: "Microsoft YaHei";
  font-size: 16px;
  color: #333;
  line-height: 20px;
}
.mod-main .mt:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.mod-main .mt .extra-r {
  float: right;
}
div.mod-coupon .mt .extra-r a {
  font-size: 14px;
  font-family: "Microsoft YaHei";
}
a {
  text-decoration: none;
}
div.mod-coupon .coupon-icon {
  display: inline-block;
  width: 22px;
  height: 20px;
  background: url(//misc.360buyimg.com/user/myjd/coupon/css/i/c-mt-icon.png)
    no-repeat;
  margin-right: 5px;
  vertical-align: middle;
}
</style>
