<template>
  <main>
    <el-col :span="6">
      <el-menu router :default-active='$route.path'>
        <el-menu-item index="/myorder" :route="{ name: 'myorder' }">
          <i class="el-icon-menu"></i>
          <span slot="title">我的订单</span>
        </el-menu-item>
        <el-menu-item index="/mycoupon" :route="{ name: 'mycoupon' }">
          <i class="el-icon-setting"></i>
          <span slot="title">我的优惠券</span>
        </el-menu-item>
        <el-submenu index="4">
          <template slot="title"><i class="el-icon-setting"></i>地址管理</template>
          <el-menu-item index="/addresslist" :route="{ name: 'addresslist' }"><i class="el-icon-menu"></i>地址列表
          </el-menu-item>
          <el-menu-item index="/addressmanage" :route="{ name: 'addressmanage' }"><i class="el-icon-menu"></i>新增地址
          </el-menu-item>
        </el-submenu>
        <el-menu-item index="/myinfo" :route="{ name: 'myinfo' }">
          <i class="el-icon-setting"></i>
          <span slot="title">编辑资料</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </main>
</template>

<script>
export default {
  data() {
    let loginF = true;
    if (window.localStorage.getItem('token')) {
      loginF = false;
    }
    return {
      styleObject: {
        display: 'none',
      },
      loginFlag: loginF,
      loginText: loginF ? '登录' : '登出',
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      // eslint-disable-next-line no-console
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // eslint-disable-next-line no-console
      console.log(key, keyPath);
    },
    showBag() {
      if (this.styleObject.display === 'block') {
        this.styleObject.display = 'none';
      } else {
        this.styleObject.display = 'block';
        this.styleObject.right = '7px';
      }
    },
    goLogin() {
      if (this.loginFlag) {
        this.$router.push({
          name: 'login',
          query: {
            mercno: this.$route.query.mercno,
          },
        });
      } else {
        this.$http.post('api/loginout').then((rep) => {
          if (rep.data.code === 0) {
            this.loginFlag = true;
            this.loginText = '登录';
            this.$toast('登出成功');
            window.localStorage.removeItem('token');
            window.location.reload();
          } else {
            this.$toast(`登出失败:${rep.data.msg}`);
            window.localStorage.removeItem('token');
          }
        });
      }
    },
    goShopping() {
      this.$router.push({
        name: 'shopping',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    goShoppingcart() {
      this.$router.push({
        name: 'shoppingcart',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    // goAddress() {
    //   this.$router.push({
    //     name: 'addressmanage',
    //     query: {
    //       mercno: this.$route.query.mercno,
    //     },
    //   });
    // },
    goAddressList() {
      this.$router.push({
        name: 'addresslist',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    goMyOrder() {
      this.$router.push({
        name: 'mycenter',
        query: {
          select: '3',
        },
      });
    },
    goMyCoupon() {
      this.$router.push({
        name: 'mycoupon',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    goCouponList() {
      this.$router.push({
        name: 'couponlist',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    goMyInfo() {
      this.$router.push({
        name: 'mycenter',
        query: {
          select: '2',
        },
      });
    },
  },
};
</script>
