// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import VueResource from 'vue-resource';
import VueClipboard from 'vue-clipboard2';
import MintUI from 'mint-ui';
import ElementUI from 'element-ui';
// import Checkbox from 'vue-material-checkbox';
// import PrettyCheckbox from 'pretty-checkbox-vue';
// import 'element-ui/lib/theme-default/index.css';
import 'mint-ui/lib/style.css';
import 'font-awesome/css/font-awesome.css';
import 'element-ui/lib/theme-default/index.css';
import './css/ac-globalnav.built.css';
import './css/ac-globalfooter.built.css';
import './css/global.css';
import './css/ac-nav.css';
import './css/webstyle.css';
import './css/member.css';
import './css/swiper.min.css';
import router from './router';
import PageFooter from './pages/components/PageFooter';
import PageHeader from './pages/components/PageHeader';
import CouponItem from './pages/components/CouponItem';
import App from './App';

Vue.config.productionTip = false;
// const options = {};

Vue.use(VueResource);
Vue.use(ElementUI);
Vue.use(MintUI);
// Vue.use(VueHtml5Editor, options);
Vue.component('PageFooter', PageFooter);
Vue.use(PageFooter);
Vue.component('PageHeader', PageHeader);
Vue.use(PageHeader);
Vue.use(CouponItem);
Vue.component('CouponItem', CouponItem);
Vue.use(VueClipboard);

Vue.prototype.linkMQ = () => {
  // _MEIQIA('showPanel'); // eslint-disable-line
};

const EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: () => { // eslint-disable-line
      return EventBus;
    }
  }
});

// Vue.http.options.root = 'api';
// Vue.http.options.root = 'api';
// Vue.http.headers.common.token = window.localStorage.getItem('token');
Vue.http.interceptors.push((request, next) => {
  if (window.localStorage.getItem('token')) {
    Vue.http.headers.common.token = window.localStorage.getItem('token');
  }
  next((response) => {
    if (response.status === 400) {
      MintUI.Toast({
        message: response.data.msg,
        duration: 2000,
      });
    }
  });
});

Vue.prototype.ScrollTop = () => {
  window.scrollTo(0, 0);
};


// const app = new Vue({
//   router,
// });

// app.$mount('#app');

const app = new Vue({
  router,
  render: h => h(App)
});

app.$mount('#app');
