/* eslint-disable global-require */

import Vue from 'vue';
import Router from 'vue-router';


Vue.use(Router);
// const UserPosts = { template: '<div>Posts</div>' };

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'app',
      component: (resolve) => require(['@/pages/Index'], resolve), // eslint-disable-line
    },
    {
      path: '/center',
      name: 'center',
      component: (resolve) => require(['@/pages/mycenter/Index'], resolve), // eslint-disable-line
      children: [
        {
          path: 'order',
          name: 'centerorder',
          component: (resolve) => require(['@/pages/mycenter/MyOrder'], resolve), // eslint-disable-line
        },
        {
          path: 'orderdetail',
          name: 'centerorderdetail',
          component: (resolve) => require(['@/pages/mycenter/OrderDetail'], resolve), // eslint-disable-line
        },
        {
          path: 'saleorderdetail',
          name: 'centersaleorderdetail',
          component: (resolve) => require(['@/pages/mycenter/SaleOrderDetail'], resolve), // eslint-disable-line
        },
        {
          path: 'coupon',
          name: 'centercoupon',
          component: (resolve) => require(['@/pages/mycenter/MyCoupon'], resolve), // eslint-disable-line
        },
        {
          path: 'couponlist',
          name: 'centercouponlist',
          component: (resolve) => require(['@/pages/mycenter/CouponList'], resolve), // eslint-disable-line
        },
        {
          path: 'shoppingcar',
          name: 'centershoppingcar',
          component: (resolve) => require(['@/pages/mycenter/ShoppingCart'], resolve), // eslint-disable-line
        },
        {
          path: 'addresslist',
          name: 'centeraddresslist',
          component: (resolve) => require(['@/pages/mycenter/AddressList'], resolve), // eslint-disable-line
        },
        {
          path: 'addressmanage',
          name: 'centeraddressmanage',
          component: (resolve) => require(['@/pages/mycenter/Address'], resolve), // eslint-disable-line
        },
        {
          path: 'centerphonesetting',
          name: 'centerphonesetting',
          component: (resolve) => require(['@/pages/mycenter/PhoneSetting'], resolve), // eslint-disable-line
        },
        {
          path: 'centerpwdsetting',
          name: 'centerpwdsetting',
          component: (resolve) => require(['@/pages/mycenter/PwdChange'], resolve), // eslint-disable-line
        },
        {
          path: 'centermyinfo',
          name: 'centermyinfo',
          component: (resolve) => require(['@/pages/mycenter/MyInfo'], resolve), // eslint-disable-line
        },
        {
          path: 'centerysdd',
          name: 'centerysdd',
          component: (resolve) => require(['@/pages/mycenter/mySale'], resolve), // eslint-disable-line
        },
        {
          path: 'centerzysp',
          name: 'centerzysp',
          component: (resolve) => require(['@/pages/mycenter/goods/list'], resolve), // eslint-disable-line
        },
        {
          path: 'centerzysp_detail',
          name: 'centerzysp_detail',
          component: (resolve) => require(['@/pages/mycenter/goods/detail'], resolve), // eslint-disable-line
        },
        {
          path: 'centerzysp_new',
          name: 'centerzysp_new',
          component: (resolve) => require(['@/pages/mycenter/goods/new'], resolve), // eslint-disable-line
        },
        {
          path: 'centerwdzd',
          name: 'centerwdzd',
          component: (resolve) => require(['@/pages/mycenter/pay/MyPayOrder'], resolve), // eslint-disable-line
        },
        {
          path: 'centerwdzd_list',
          name: 'centerwdzd_list',
          component: (resolve) => require(['@/pages/mycenter/pay/MyPayList'], resolve), // eslint-disable-line
        },
        {
          path: 'centerwdzd_detail',
          name: 'centerwdzd_detail',
          component: (resolve) => require(['@/pages/mycenter/pay/MyPayDetail'], resolve), // eslint-disable-line
        },
        {
          path: 'centerwdzd_apply',
          name: 'centerwdzd_apply',
          component: (resolve) => require(['@/pages/mycenter/pay/MyPayApply'], resolve), // eslint-disable-line
        },
        {
          path: 'centerrygl',
          name: 'centerrygl',
          component: (resolve) => require(['@/pages/mycenter/myMember'], resolve), // eslint-disable-line
        },
        {
          path: 'centervipcoupon',
          name: 'centervipcoupon',
          component: (resolve) => require(['@/pages/mycenter/MyVipCoupon'], resolve), // eslint-disable-line
        },
        {
          path: 'centervipcoupondetail',
          name: 'centervipcoupondetail',
          component: (resolve) => require(['@/pages/mycenter/MyVipCouponDetail'], resolve), // eslint-disable-line
        },
        {
          path: 'centercreatevipcoupon',
          name: 'centercreatevipcoupon',
          component: (resolve) => require(['@/pages/mycenter/CreateCoupon'], resolve), // eslint-disable-line
        }
      ],
    },
    {
      path: '/reading',
      name: 'reading',
      component: (resolve) => require(['@/pages/index2/reading/Reading'], resolve), // eslint-disable-line
    },
    {
      path: '/assistant',
      name: 'assistant',
      component: (resolve) => require(['@/pages/index2/assistant/Assistant'], resolve), // eslint-disable-line
    },
    {
      path: '/english',
      name: 'english',
      component: (resolve) => require(['@/pages/index2/english/English'], resolve), // eslint-disable-line
    },
    {
      path: '/experiment',
      name: 'experiment',
      component: (resolve) => require(['@/pages/index2/experiment/Experiment'], resolve), // eslint-disable-line
    },
    {
      path: '/art',
      name: 'art',
      component: (resolve) => require(['@/pages/index2/art/Art'], resolve), // eslint-disable-line
    },
    {
      path: '/think',
      name: 'think',
      component: (resolve) => require(['@/pages/index2/think/Think'], resolve), // eslint-disable-line
    },
    {
      path: '/view',
      name: 'view',
      component: (resolve) => require(['@/pages/index2/view/View'], resolve), // eslint-disable-line
    },
    {
      path: '/cl',
      name: 'cl',
      component: (resolve) => require(['@/pages/index2/cl/CL'], resolve), // eslint-disable-line
    },
    {
      path: '/cloth',
      name: 'cloth',
      component: (resolve) => require(['@/pages/index2/cloth/Cloth'], resolve), // eslint-disable-line
    },
    {
      path: '/about',
      name: 'about',
      component: (resolve) => require(['@/pages/about/About'], resolve), // eslint-disable-line
    },
    {
      path: '/aboutdetail',
      name: 'aboutdetail',
      component: (resolve) => require(['@/pages/about/AboutDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/aboutjob',
      name: 'aboutjob',
      component: (resolve) => require(['@/pages/about/AboutJob'], resolve), // eslint-disable-line
    },
    {
      path: '/aboutjobapply',
      name: 'aboutjobapply',
      component: (resolve) => require(['@/pages/about/AboutJobApply'], resolve), // eslint-disable-line
    },
    {
      path: '/mycoupon',
      name: 'mycoupon',
      component: (resolve) => require(['@/pages/coupon/MyCoupon'], resolve), // eslint-disable-line
    },
    {
      path: '/couponlist',
      name: 'couponlist',
      component: (resolve) => require(['@/pages/coupon/CouponList'], resolve), // eslint-disable-line
    },

    {
      path: '/shopping',
      name: 'shopping',
      component: (resolve) => require(['@/pages/shopping/Shopping'], resolve), // eslint-disable-line
    },
    {
      path: '/shoppingcategorylist',
      name: 'shoppingcategorylist',
      component: (resolve) => require(['@/pages/shopping/ShoppingCategoryList'], resolve), // eslint-disable-line
    },
    {
      path: '/shoppinglist',
      name: 'shoppinglist',
      component: (resolve) => require(['@/pages/shopping/ShoppingList'], resolve), // eslint-disable-line
    },
    {
      path: '/shoppingdetail',
      name: 'shoppingdetail',
      component: (resolve) => require(['@/pages/shopping/ShoppingDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/shoppingcart',
      name: 'shoppingcart',
      component: (resolve) => require(['@/pages/shopping/ShoppingCart'], resolve), // eslint-disable-line
    },
    {
      path: '/addressmanage',
      name: 'addressmanage',
      component: require('@/pages/address/Address'),
      component: (resolve) => require(['@/pages/address/Address'], resolve), // eslint-disable-line
    },
    {
      path: '/addresslist',
      name: 'addresslist',
      component: (resolve) => require(['@/pages/address/AddressList'], resolve), // eslint-disable-line
    },
    {
      path: '/mycenter',
      name: 'mycenter',
      component: (resolve) => require(['@/pages/mine/MyCenter'], resolve), // eslint-disable-line
    },
    {
      path: '/myorder',
      name: 'myorder',
      component: (resolve) => require(['@/pages/mine/MyOrder'], resolve), // eslint-disable-line
    },
    {
      path: '/myinfo',
      name: 'myinfo',
      component: (resolve) => require(['@/pages/mine/MyInfo'], resolve), // eslint-disable-line
    },
    {
      path: '/forgetpwd',
      name: 'forgetpwd',
      component: (resolve) => require(['@/pages/mine/PwdSetting'], resolve), // eslint-disable-line
    },
    {
      path: '/login',
      name: 'login',
      component: (resolve) => require(['@/pages/mine/Login'], resolve), // eslint-disable-line
    },
    {
      path: '/register',
      name: 'register',
      component: (resolve) => require(['@/pages/mine/Register'], resolve), // eslint-disable-line
    },
    {
      path: '/order',
      name: 'order',
      component: (resolve) => require(['@/pages/order/Order'], resolve), // eslint-disable-line
    },
    {
      path: '/beforeorder',
      name: 'beforeorder',
      component: (resolve) => require(['@/pages/order/BeforeOrder'], resolve), // eslint-disable-line
    },
    {
      path: '/beforeaddress',
      name: 'beforeaddress',
      component: (resolve) => require(['@/pages/order/BeforeAddress'], resolve), // eslint-disable-line
    },
    {
      path: '/beforecheck',
      name: 'beforecheck',
      component: (resolve) => require(['@/pages/order/BeforeCheck'], resolve), // eslint-disable-line
    },
    {
      path: '/checkorder',
      name: 'checkorder',
      component: (resolve) => require(['@/pages/order/CheckOrder'], resolve), // eslint-disable-line
    },
    {
      path: '/checkpay',
      name: 'checkpay',
      component: (resolve) => require(['@/pages/order/CheckPay'], resolve), // eslint-disable-line
    },
    {
      path: '/orderdetail',
      name: 'orderdetail',
      component: (resolve) => require(['@/pages/order/OrderDetail'], resolve), // eslint-disable-line
    },
    {
      path: '/orderdone',
      name: 'orderdone',
      component: (resolve) => require(['@/pages/order/OrderDone'], resolve), // eslint-disable-line
    },
    {
      path: '/news',
      name: 'news',
      component: (resolve) => require(['@/pages/news/News'], resolve), // eslint-disable-line
    },
    {
      path: '/newsdetail',
      name: 'newsdetail',
      component: (resolve) => require(['@/pages/news/Newsdetail'], resolve), // eslint-disable-line
    },
    // {newsdetail
    //   path: '/regester',
    //   name: 'regester',
    //   component: require('@/pages/Regester'),
    // },
    // {
    //   path: '/services',
    //   name: 'services',
    //   component: require('@/pages/Services'),
    // },
    // {
    //   path: '/merchantinfo',
    //   name: 'merchantinfo',
    //   component: require('@/pages/MerchantInfo'),
    // },
    // {
    //   path: '/shopcenter',
    //   name: 'shopcenter',
    //   component: require('@/pages/ShopCenter'),
    // },
    // {
    //   path: '/orderdetail',
    //   name: 'orderdetail',
    //   component: require('@/pages/Orderdetail'),
    // },
    // {
    //   path: '/userbankinfo',
    //   name: 'userbankinfo',
    //   component: require('@/pages/Userbankinfo'),
    // },
    // {
    //   path: '/userpwdma',
    //   name: 'userpwdma',
    //   component: require('@/pages/Userpwdma'),
    // },
  ],
});
