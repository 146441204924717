<template>
  <main>
    <div style="height: 148px;">
      <nav id="ac-globalnav" class="js no-touch">
        <div class="top-banner">
          <div class="inner">
            <a href="/news">新闻资讯</a>
            <a href="/about">关于荟乐</a>
            <a href="/about?selected=6">联系我们</a>
            <a href="/about?selected=7">招聘信息</a>
          </div>
        </div>
        <div class="ac-gn-content">
          <ul class="ac-gn-list">
            <li class="ac-gn-item ac-gn-apple">
              <a class="ac-gn-link ac-gn-link-apple" href="/" id="ac-gn-firstfocus" style="width: 165px;">
                <span style="top: -60px;width: 403px;left: -150px;" class="ac-gn-link-text ac-gn-link-text-2">
                  <img style="width:100%;" src='/static/images/index/index-logo.png' alt="荟乐教育logo"/>首页</span>
              </a>
            </li>
            <li :key='index' v-for='(item, index) in getFirstFiveMenu()' class="ac-gn-item ac-gn-item-menu" :style="getIndex(index)">
              <a v-if="index < 6" class="ac-gn-link ac-gn-link-apple" :href='item.linkurl' id="ac-gn-firstfocus">
                <span class="ac-gn-link-text ac-gn-link-text-2">{{item.classname}}</span>
              </a>
            </li>
            <li style="margin-right: 0px;width: 60px;" class="ac-gn-item ac-gn-bag" id="ac-gn-bag-1" @click="showMore">
              <a class="ac-gn-link ac-gn-link-bag more" style="padding: 0 18px;">
                <span class="ac-gn-bag-badge"></span>
              </a>
              <span class="ac-gn-bagview-caret ac-gn-bagview-caret-large"></span>
            </li>
            <li style="width: 60px;" class="ac-gn-item ac-gn-bag" id="ac-gn-bag-2" @click="showBag">
              <a class="ac-gn-link ac-gn-link-bag" style="padding: 0 10px;">
                <span class="ac-gn-bag-badge"></span>
              </a>
              <span class="ac-gn-bagview-caret ac-gn-bagview-caret-large"></span>
            </li>
          </ul>
          <aside id = 'aside1' @click="hideSide" class="ac-gn-bagview" v-bind:style="styleObject">
            <div class="ac-gn-bagview-scrim">
              <span class="ac-gn-bagview-caret ac-gn-bagview-caret-small" v-bind:style="styleObject"></span>
            </div>
            <div class="ac-gn-bagview-content" id="ac-gn-bagview-content">
              <nav class="ac-gn-bagview-nav">
                <ul class="ac-gn-bagview-nav-list ac-gn-bagview-nav-nobtn" style="border-top:none;">
                  <li class="ac-gn-bagview-nav-item ac-gn-bagview-nav-item-bag" style="border-top:none;">
                    <a @click="goShoppingcart" class="ac-gn-bagview-nav-link ac-gn-bagview-nav-link-bag">
                      购物车
                    </a>
                  </li>
                  <li class="ac-gn-bagview-nav-item ac-gn-bagview-nav-item-orders">
                    <a @click="goMyCoupon" class="ac-gn-bagview-nav-link ac-gn-bagview-nav-link-coupon">
                      我的优惠券
                    </a>
                  </li>
                  <li class="ac-gn-bagview-nav-item ac-gn-bagview-nav-item-orders">
                    <a @click="goMyOrder" class="ac-gn-bagview-nav-link ac-gn-bagview-nav-link-orders">
                      订单管理
                    </a>
                  </li>
                  <li class="ac-gn-bagview-nav-item ac-gn-bagview-nav-item-orders">
                    <a @click="goAddressList" class="ac-gn-bagview-nav-link ac-gn-bagview-nav-link-address">
                      地址管理
                    </a>
                  </li>
                  <li class="ac-gn-bagview-nav-item ac-gn-bagview-nav-item-account">
                    <a @click="goMyInfo" class="ac-gn-bagview-nav-link ac-gn-bagview-nav-link-account">
                      个人中心
                    </a>
                  </li>
                  <li class="ac-gn-bagview-nav-item ac-gn-bagview-nav-item-signIn">
                    <a @click="goLogin" class="ac-gn-bagview-nav-link ac-gn-bagview-nav-link-signIn">
                      {{loginText}}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </aside>

          <aside id = 'aside2' @click="hideSide" class="ac-gn-bagview" v-bind:style="styleMoreObject">
            <div class="ac-gn-bagview-scrim">
              <span class="ac-gn-bagview-caret ac-gn-bagview-caret-small" v-bind:style="styleMoreObject"></span>
            </div>
            <div class="ac-gn-bagview-content" id="ac-gn-bagview-content">
              <nav class="ac-gn-bagview-nav">
                <ul class="ac-gn-bagview-nav-list ac-gn-bagview-nav-nobtn" style="border-top:none;">
                  <li :key='index' v-for='(item, index) in getLastMenu()' class="more-nav-item" style="">
                    <a style="width: 100%;margin: 0;padding: 0;" v-if="index < 6" class="ac-gn-link ac-gn-link-apple ac-gn-bagview-nav-link" :href='item.linkurl' id="ac-gn-firstfocus">
                      <span style="width: 100%;" class="ac-gn-link-text ac-gn-link-text-2">{{item.classname}}</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </aside>
        </div>
      </nav>
    </div>
    <div v-if="subTitle!=''" style="text-indent: 80px;max-width: 980px;margin:0 auto;font-size: 20px;line-height: 0.3rem;padding-top: 5px;">{{subTitle}}</div>
  </main>
</template>

<script>
export default {
  props: {
    subTitle: '',
  },
  data() {
    let loginF = true;
    if (window.localStorage.getItem('token')) {
      loginF = false;
    }
    return {
      styleObject: {
        display: 'none',
      },
      styleMoreObject: {
        display: 'none',
      },
      headMenus: [],
      loginFlag: loginF,
      loginText: loginF ? '登录' : '登出',
    };
  },
  methods: {
    hideSide(event) {
      const aside1 = document.getElementById('aside1');
      if (!aside1.contains(event.target)) {
        this.styleMoreObject.display = 'none';
      }
    },
    getIndex(index) {
      let style = {
      };
      if (index >= 6) {
        style = {
          'flex-grow': 0.4,
          'flex-shrink': 1,
          'flex-basis': '0%'
        };
        style = {
        };
      }
      return style;
    },
    getFirstFiveMenu() {
      const output = [];
      this.headMenus.forEach((item, index) => {
        if (index < 6) {
          output.push(item);
        }
      });
      return output;
    },
    getLastMenu() {
      const output = [];
      this.headMenus.forEach((item, index) => {
        if (index >= 6) {
          output.push(item);
        }
      });
      return output;
    },
    showBag() {
      this.styleMoreObject.display = 'none';
      if (this.styleObject.display === 'block') {
        this.styleObject.display = 'none';
      } else {
        this.styleObject.display = 'block';
        this.styleObject.right = '23px';
      }
    },
    showMore() {
      this.styleObject.display = 'none';
      if (this.styleMoreObject.display === 'block') {
        this.styleMoreObject.display = 'none';
      } else {
        this.styleMoreObject.display = 'block';
        this.styleMoreObject.right = '76px';
      }
    },
    goLogin() {
      if (this.loginFlag) {
        this.$router.push({
          name: 'login',
          query: {
            mercno: this.$route.query.mercno,
          },
        });
      } else {
        this.$http.post('/api/loginout').then((rep) => {
          if (rep.data.code === 0) {
            this.loginFlag = true;
            this.loginText = '登录';
            this.$toast('登出成功');
            window.localStorage.removeItem('token');
            window.location.reload();
          } else {
            this.$toast(`登出失败:${rep.data.msg}`);
            window.localStorage.removeItem('token');
            this.$router.push({
              name: 'login',
            });
          }
        });
      }
    },
    goShopping() {
      this.$router.push({
        name: 'shopping',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    goShoppingcart() {
      this.$router.push({
        name: 'centershoppingcar',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    goAddressList() {
      this.$router.push({
        name: 'centeraddresslist',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    goMyOrder() {
      this.$router.push({
        name: 'centerorder',
      });
    },
    goMyCoupon() {
      this.$router.push({
        name: 'centercoupon',
        query: {
          mercno: this.$route.query.mercno,
        },
      });
    },
    goMyInfo() {
      this.$router.push({
        name: 'centerorder',
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener('click', (event) => {
        let aside1 = document.getElementById('ac-gn-bag-1');
        if (!aside1.contains(event.target)) {
          this.styleMoreObject.display = 'none';
        }
        aside1 = document.getElementById('ac-gn-bag-2');
        if (!aside1.contains(event.target)) {
          this.styleObject.display = 'none';
        }
      });
      this.$http.post('/api/nav/list').then((rep) => {
        if (rep.data.code === 401) {
          if (rep.data.msg === 'token失效，请重新登录' || rep.data.msg === 'token不能为空') {
            this.$toast('需要您登录');
            this.$router.push({
              name: 'login',
            });
          } else {
            this.$toast(rep.data.msg);
          }
        } else {
          this.headMenus = rep.data.headMenus;
          // const scope = this;
          // rep.data.headMenus.map((item, index) => { // eslint-disable-line array-callback-return
          //   scope.$set(scope.headMenus, index, item);
          // });
        }
      });
    });
  },
};
</script>
